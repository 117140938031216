/* This injects Tailwind's base styles and any base styles registered by plugins. */
@tailwind base;
/* This injects Tailwind's component classes and any component classes registered by plugins. */
@tailwind components;
/* This injects Tailwind's utility classes and any utility classes registered by plugins. */
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

@layer base {
  html {
    font-family: "Noto Sans TC", "Inter", "Noto Sans", Roboto, Helvetica, Arial,
      "Noto Sans TC", sans-serif;
  }
  h1 {
    @apply text-[34px] font-[500] leading-[1.35] tracking-[-0.5px];
  }
  h2 {
    @apply text-[28px] font-[500] leading-[1.35] tracking-[-0.5px];
  }
  h3 {
    @apply text-[24px] font-[500] leading-[1.35] tracking-[-0.5px];
  }
  h4 {
    @apply text-[22px] font-[600] leading-[1.35] tracking-[-0.5px];
  }
  h5 {
    @apply text-[20px] font-[600] leading-[1.35] tracking-[-0.5px];
  }
  h6 {
    @apply text-[18px] font-[600] leading-[1.35] tracking-[-0.5px];
  }
  :lang(zh) {
    font-family: "Noto Sans TC", "Inter", "Noto Sans", Roboto, Helvetica, Arial,
      sans-serif;
  }
  :lang(en) {
    font-family: "Noto Sans TC", "Inter", "Noto Sans", Roboto, Helvetica, Arial,
      sans-serif;
  }

  /* Feature 1 Classes */
  .feature-text1 {
    color: var(--Text-Primary, #1a1a1a);
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: "Noto Sans TC";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .feature-text2 {
    background: linear-gradient(
      90deg,
      #0060e5 30.25%,
      #1a65e8 34.17%,
      #476eee 41.78%,
      #5972f0 45.61%,
      #7871d8 50.98%,
      #b170ad 61.97%,
      #dc6f8d 71.39%,
      #f56e7a 78.74%,
      #ff6e72 83.13%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-variant-numeric: lining-nums tabular-nums;

    /* H1 */
    font-family: "Noto Sans TC";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .feature1-text3 {
    color: var(--Text-Primary, #1a1a1a);
    font-variant-numeric: lining-nums tabular-nums;

    /* H2 */
    font-family: "Noto Sans TC";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .feature1-text4 {
    color: var(--Text-Primary, #1a1a1a);
    font-variant-numeric: lining-nums tabular-nums;
    font-family: "Noto Sans TC";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
  }

  .feature1-button-active {
    display: flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: var(--Main, #0060e5);
  }

  .feature1-label-active {
    color: var(--Color, #fff);
    text-align: center;
    font-family: "Noto Sans TC";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  .feature1-button {
    display: flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .feature1-label {
    color: #a7a7a6;
    text-align: center;
    font-family: "Noto Sans TC";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  .feature1-rounded-text {
    color: var(--Main, #0060e5);
    font-variant-numeric: lining-nums tabular-nums;

    /* Paragraph */
    font-family: "Noto Sans TC";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 188.889% */
  }

  /* Feature 2 Classes*/
  .feature2-text1 {
    color: var(--Text-Primary, #1a1a1a);
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums;

    /* H1 */
    font-family: "Noto Sans TC";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .feature2-text2 {
    background: linear-gradient(
      90deg,
      #ff6e72 44.81%,
      #f56e7a 46.88%,
      #dc6f8d 50.35%,
      #b170ad 54.79%,
      #7871d8 59.98%,
      #5972f0 62.5%,
      #476eee 64.31%,
      #1a65e8 67.9%,
      #0060e5 69.75%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-variant-numeric: lining-nums tabular-nums;

    /* H1 */
    font-family: "Noto Sans TC";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .feature2-rounded-text {
    color: var(--Secondary, #ff6e72);
    font-variant-numeric: lining-nums tabular-nums;
    font-family: "Noto Sans TC";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  /* How to Use Classes */
  .howtouse-text1 {
    color: var(--Color, #fff);
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums;

    /* H2 */
    font-family: "Noto Sans TC";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .howtouse-text2 {
    background: linear-gradient(271deg, #fc817c 0.73%, #0c85ff 47.3%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-variant-numeric: lining-nums tabular-nums;

    /* H2 */
    font-family: "Noto Sans TC";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .howtouse-text3 {
    color: var(--Color, #fff);

    /* H4 */
    font-family: "Noto Sans TC";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .howtouse-text4 {
    color: var(--Text-Tertiary, #999);
    text-align: center;

    /* Caption */
    font-family: "Noto Sans TC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .navbar-text1 {
    color: #000;
    text-align: center;
    font-family: "Noto Sans TC";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 111.111% */
  }

  .howtouse2-text1 {
    color: var(--Text-Primary, #1a1a1a);
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums;

    /* H2 */
    font-family: "Noto Sans TC";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .howtouse2-text2 {
    color: var(--Color, #fff);
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums;
    font-family: "Noto Sans TC";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .testimonials-text1 {
    color: #fff;
    text-align: center;
    font-variant-numeric: lining-nums tabular-nums;

    /* H1 */
    font-family: "Noto Sans TC";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  /* How to Use 2 */
  .grayscale-photo {
    filter: grayscale(100%);
    transition: filter 0.3s ease;
  }

  .grayscale-photo:hover {
    filter: grayscale(0%);
  }
}

.Subtitle1 {
  @apply text-[16px] font-[500] leading-[1.35] tracking-[0px];
}

.Subtitle2 {
  @apply text-[14px] font-[400] leading-[1.35] tracking-[0px];
}

.body1 {
  @apply text-[16px] font-[400] leading-[1.4] tracking-[0.25px]  text-gray-800;
}

.body2 {
  @apply text-[14px] font-[400] leading-[1.4] tracking-[0.25px];
}

.Caption {
  @apply text-[12px] font-[400] leading-[1.25] tracking-[0.25px];
}

.Overline {
  @apply text-[12px] font-[500] leading-[1.25] tracking-[1.5px];
}

.ButtonL {
  @apply text-[16px] font-[500] leading-[1.5] tracking-[0px];
}

.ButtonM {
  @apply text-[14px] font-[500] leading-[1.5] tracking-[0px];
}

.ButtonS {
  @apply text-[12px] font-[500] leading-[1.5] tracking-[0px];
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

@layer components {
  .full-height-layout {
    @apply flex flex-col;
    min-height: calc(var(--vh));
    height: 100dvh;
  }

  .full-height-layout .header,
  .full-height-layout .footer {
    @apply flex-shrink-0;
  }

  .full-height-layout .main-body {
    @apply flex-grow overflow-y-auto py-2 px-3 bg-color-Grey50;
  }

  .full-height-layout .main-body2 {
    @apply flex-grow overflow-y-auto py-2 px-3 bg-color-White;
  }
}

html,
body {
  height: 100dvh;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

:root {
  --vh: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@layer utilities {
  .animate-spin-slow {
    width: 77px;
    animation: spin 3s linear infinite; /* Slows down to 3 seconds */
  }
}
